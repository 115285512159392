<template>
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Purchase Request">
        <b-form @keyup.enter.prevent="" @submit.prevent="submitNewPurchaseRequest">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <b-form-group
                label="Class *"
                label-for="purchase_request_class"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenClass" :options="classes" />
              </b-form-group>
              <b-form-group
                label="Project"
                label-for="purchase_request_project"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_request_project"
                  v-model="purchaseRequestProject"
                  placeholder="Project"
                />
              </b-form-group>
              <b-form-group
                label="Deliver To"
                label-for="purchase_request_deliver_to"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_request_deliver_to"
                  v-model="purchaseRequestDeliverTo"
                  placeholder="Deliver To"
                />
              </b-form-group>
              <b-form-group
                label="Category"
                label-for="purchase_request_category"
                label-cols-md="12"
              >
                <v-select-new v-model="purchaseRequestCategory" :options="categories" required/>
              </b-form-group>
              <b-form-group
                label="Asset Group"
                label-for="purchase_request_category_asset"
                label-cols-md="12"
                v-if="purchaseRequestCategory=='Assets'"
              >
                <v-select-new v-model="purchaseRequestCategoryAsset" :options="assetCategories" required/>
              </b-form-group>
              <b-form-group
                label="Need For"
                label-for="purchase_request_need_for"
                label-cols-md="12"
              >
                <v-select-new v-model="purchaseRequestNeedFor" :options="needs" required/>
              </b-form-group>
              <b-form-group
                label="Cost Center *"
                label-for="purchase_request_cost_center"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenBranch" :options="branches" />
              </b-form-group>
              <b-form-group
                label="Type *"
                label-for="purchase_requet_type"
                label-cols-md="12"
              >
                <v-select-new v-model="chosenType" :options="types" />
              </b-form-group>
              <b-form-group
                label="Notes"
                label-for="purchase_request_notes"
                label-cols-md="12"
              >
                <b-form-input
                  id="purchase_request_notes"
                  v-model="purchaseRequestNotes"
                  placeholder="Notes"
                />
              </b-form-group>
              <b-form-group label="Items *" label-for="chosenItem">
                <v-select-new
                  v-model="chosenItem"
                  :filterable="true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="items"
                  :get-option-label="getOptionLabel"
                  :get-option-key="getOptionKey"
                  @input="putItem"
                  @search="inputSearch"
                />
              </b-form-group>
              <b-row>
                <b-col cols="3" class="text-center font-weight-bold">
                  Item name
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Note
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Unit name
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  Item QTY
                </b-col>
                <b-col cols="2" class="text-center font-weight-bold">
                  AVG Req
                </b-col>
                <b-col cols="1" class="text-center font-weight-bold">
                  Actions
                </b-col>
              </b-row>
              <p />
              <div>
              <b-row v-for="chosen in chosens" :key="chosen.value">
                <br />
                <b-col cols="3 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ chosen.label }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-list-group>
                  <b-form-input
                      v-model="chosen.note"
                      type="text"
                      placeholder="Note"
                      :max="chosen.note"
                    />
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-list-group>
                    <b-list-group-item href="#">
                      {{ capitalizeWords(chosen.unit_name) }}
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                    v-model="chosen.qty"
                    type="number"
                    placeholder="Qty"
                    :max="chosen.qty"
                    required="required"
                  />
                </b-col>
                <b-col cols="2 text-center">
                  <b-form-input
                    v-model="chosen.avg"
                    type="text"
                    placeholder="Average Req/Month"
                    required="required"
                  />
                </b-col>
                <b-col cols="1 text-center">
                  <b-button
                    size="sm"
                    type="button"
                    variant="danger"
                    class="mr-1"
                    @click="removeRow(chosen.value)"
                  >
                    <feather-icon icon="TrashIcon" size="18" />
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <br />
                </b-col>
              </b-row>
              </div>
            </b-col>
            <!-- submit and reset -->
            <b-col offset-md="12">
              <b-button type="submit" variant="primary" class="mr-1"
                :disabled="submitButtonDisabled">
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import axios from "axios"
import moment from "moment"
import Ripple from "vue-ripple-directive"

import { ref } from "@vue/composition-api"

const types = ["Headoffice", "Cabang"]
const chosenType = "Cabang"
const VersionCheck = ""
const items = []
const itemsUnfiltered = []
const chosenItem = ref()
const chosens = []
const purchaseRequestClass = ""
const purchaseRequestProject = ""
const purchaseRequestCostCenter = ""
const purchaseRequestQtyRequested = 0
const purchaseRequestStatus = ""
const purchaseRequestNotes = ""
const purchaseRequestDeliverTo = ""
const purchaseRequestCategory = ""
const purchaseRequestCategoryAsset = ""
const purchaseRequestNeedFor = ""
const chosenBranch = ""
const branches = []
const chosenClass = ""
const classes = ["Urgent", "Normal"]
const categories = ["Assets", "Supplies", "FGS"]
const needs = ["Non Inventory", "Inventory", "Projects"]
const assetCategories = ["Machinery (MC)", "Furniture & Fixture", "Office Equipment", "Lab Equipment", "Vehicle (VH)", "Building", "Software", "Low Value Asset", "Land", "Field Equipment"]
const submitButtonDisabled = false

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      assetCategories,
      needs,
      purchaseRequestNeedFor,
      purchaseRequestCategory,
      purchaseRequestCategoryAsset,
      categories,
      chosenType,
      types,
      chosenBranch,
      branches,
      chosenClass,
      classes,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      purchaseRequestClass,
      purchaseRequestProject,
      purchaseRequestCostCenter,
      purchaseRequestQtyRequested,
      purchaseRequestStatus,
      purchaseRequestNotes,
      purchaseRequestDeliverTo,
      itemsUnfiltered,
      submitButtonDisabled,
    }
  },
  created() {
    window.setInterval(() => {
      this.removeReadOnly()
    }, 1000)
  },
  watch: {
    async purchaseRequestCategory(newValue) {
      if (newValue === "Assets") {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.label.substring(0, 3) === 'ACL')
      } else if (newValue === "Supplies") {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.label.substring(0, 3) === 'WHS' || item.label.substring(0, 3) === 'SUP')
      } else if (newValue === "FGS") {
        this.chosens = []
        this.items = this.itemsUnfiltered.filter(item => item.label.substring(0, 3) === 'JSL' || item.label.substring(0, 3) === 'FGS')
      }
    },
  },
  mounted() {
    this.chosens = []
    this.getItems()
    this.getBranches()
  },
  methods: {
    removeReadOnly() {
      const searches = document.getElementsByClassName("vs__search")
      for (let i = 0; i < searches.length; i += 1) {
        searches[i].removeAttribute("readonly")
      }
    },
    getBranches() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_BRANCHES}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            let dataResp = []
            const itemsJoin = []
            const userData = this.$cookies.get("userData")
            const userBranch = userData.branch
            if (userBranch !== null && typeof userBranch !== "undefined") {
              dataResp = this.filterByBranchCode(
                response.data.data,
                userBranch.branch_code,
              )
            } else {
              dataResp = response.data.data
            }
            dataResp.map(elem => {
              if (userBranch !== null && typeof userBranch !== "undefined") {
                if (userBranch.branch_code === elem.branch_code) {
                  this.chosenBranch = {
                    label: `${elem.branch_name} (${elem.branch_code})`,
                    value: elem.branch_id,
                  }
                }
              }
              itemsJoin.push({
                label: `${elem.branch_name} (${elem.branch_code})`,
                value: elem.branch_id,
              })
            })
            this.branches = itemsJoin
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Branches Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Branches Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    filterByBranchCode(arr, code) {
      return arr.filter(item => item.branch_code === code)
    },
    getItems() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PR_PLAN}`,
          {},
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            const itemsJoin = []
            response.data.data.map(elem => {
              const temp = {
                label: `${elem.item.item_code} ${elem.item.item_name} (QTY : ${elem.purchase_request_detail_item_qty_requested}) MR : ${elem.material_request ? elem.material_request.material_request_number : '-'}`,
                unit_name: `${elem.item.unit ? elem.item.unit.unit_name : '-'}`,
                value: elem.purchase_request_detail_id,
                qty: elem.purchase_request_detail_item_qty_requested,
                avg: 0,
                note: "",
                mr_id: elem.purchase_request_detail_mr_id,
              }
              console.log(temp)
              itemsJoin.push(temp)
            })
            this.itemsUnfiltered = itemsJoin
            this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Get Data Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Get Settings Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    submitNewPurchaseRequest() {
      this.submitButtonDisabled = true
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      if (this.chosens.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please select items",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
        return
      }
      const mrIds = []
      this.chosens.map(elem => {
        this.purchaseRequestQtyRequested += parseInt(elem.qty)
        if (!mrIds.includes(elem.mr_id)) {
          mrIds.push(elem.mr_id)
        }
      })
      const body = {
        purchase_request_class: this.chosenClass,
        purchase_request_project: this.purchaseRequestProject,
        purchase_request_cost_center: this.chosenBranch.value,
        purchase_request_notes: this.purchaseRequestNotes,
        purchase_request_is_center: this.chosenType,
        purchase_request_mr_ids: mrIds,
        purchase_request_deliver_to: this.purchaseRequestDeliverTo,
        purchase_request_category: this.purchaseRequestCategory,
        purchase_request_asset_category: this.purchaseRequestCategoryAsset,
        purchase_request_need_for: this.purchaseRequestNeedFor,
        material_request_id: this.purchaseRequestQtyRequested,
        purchase_request_items: JSON.stringify(this.chosens),
        time: moment(),
      }
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_PURCHASE_REQUEST}`
      console.log(url)
      axios
        .post(url, body, { headers })
        .then(response => {
          this.submitButtonDisabled = false
          if (response.data.success === true) {
            this.chosens = []
            this.purchaseRequestQtyRequested = 0
            this.purchaseRequestCostCenter = ""
            this.purchaseRequestProject = ""
            this.purchaseRequestClass = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Purchase Request Success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
            this.$router.replace({
              name: "apps-procurement-purchase-request-list",
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Purchase Request Failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Purchase Request Failed",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    },
    getOptionLabel(option) {
      return (option && option.label) || ""
    },
    putItem(val) {
      if (this.chosens.length > 0) {
        let same = false
        this.chosens.map(elem => {
          if (elem.value === val.value) {
            same = true
          }
        })
        if (same === false) {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = {
                unit_name: elem.unit_name,
                label: elem.label,
                value: elem.value,
                qty: elem.qty,
                avg: elem.avg,
                mr_id: elem.mr_id,
                note: elem.note,
              }
            }
          })
          this.chosens.push(item)
        }
      } else {
        let item = null
        this.items.map(elem => {
          if (elem.value === val.value) {
            item = {
              unit_name: elem.unit_name,
              label: elem.label,
              value: elem.value,
              qty: elem.qty,
              avg: elem.avg,
              mr_id: elem.mr_id,
              note: elem.note,
            }
          }
        })

        this.chosens.push(item)
      }
    },
    applyItem(val) {
      console.log(val.data)
    },
    getOptionKey(option) {
      return (option && option.value) || ""
    },
    inputSearch(search) {
      console.log(search)
    },
    removeRow(val) {
      this.chosens = this.chosens.filter(obj => obj.value !== val)
    },
    dateFormat(value, format = "MM/DD/YYYY") {
      return moment(String(value)).format(format)
    },
    previous() {
      this.$router.push({ name: "apps-procurement-purchase-request-list" })
    },
    capitalizeWords(str) {
    // Split the string into words using spaces as the separator
    const words = str.split(' ')
    // Capitalize the first letter of each word and join them back together
    const capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })
    // Join the capitalized words into a single string with spaces
    return capitalizedWords.join(' ')
    },
  },
}
</script>
